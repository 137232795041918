var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Fazendas")]),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.farms,"search":_vm.search,"loading":_vm.$apollo.loading,"items-per-page":10,"sort-by":"customerName"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Procurar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.isManager)?_c('v-btn',{attrs:{"color":"primary","dark":"","to":"/fazendas/new"}},[_vm._v(" Novo ")]):_vm._e()],1)]},proxy:true},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.user.role === 'manager')?_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary","to":("/fazendas/" + (item.id))}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.user.role !== 'manager')?_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary","to":("/fazendas/" + (item.id))}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(_vm.user.role === 'manager')?_c('confirmation',{on:{"success":function () { return _vm.remove(item.id); }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"error"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green darken-3"},on:{"click":function($event){return _vm.exportFarm(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1)]}}],null,true)},[_c('span',[_vm._v("Exportar para Excel")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.user.role === 'manager' && !item.desactivated),expression:"user.role === 'manager' && !item.desactivated"}],attrs:{"text":"","icon":"","color":"green darken-3"},on:{"click":function($event){return _vm.desactivateFarm(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Ativa")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.user.role === 'manager' && item.desactivated),expression:"user.role === 'manager' && item.desactivated"}],attrs:{"text":"","icon":"","color":"error"},on:{"click":function($event){return _vm.desactivateFarm(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Inativa")])])]}}],null,true)})],1),_c('h1',{staticClass:"mb-5 mt-5"},[_vm._v("Fazendas Inativas")]),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.farmsDesactivated,"search":_vm.search,"loading":_vm.$apollo.loading,"items-per-page":10,"sort-by":"customerName"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Procurar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.user.role === 'manager')?_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary","to":("/fazendas/" + (item.id))}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.user.role !== 'manager')?_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"primary","to":("/fazendas/" + (item.id))}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(_vm.user.role === 'manager')?_c('confirmation',{on:{"success":function () { return _vm.remove(item.id); }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"error"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green darken-3"},on:{"click":function($event){return _vm.exportFarm(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-microsoft-excel")])],1)]}}],null,true)},[_c('span',[_vm._v("Exportar para Excel")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.user.role === 'manager' && !item.desactivated),expression:"user.role === 'manager' && !item.desactivated"}],attrs:{"text":"","icon":"","color":"green darken-3"},on:{"click":function($event){return _vm.desactivateFarm(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Ativa")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.user.role === 'manager' && item.desactivated),expression:"user.role === 'manager' && item.desactivated"}],attrs:{"text":"","icon":"","color":"error"},on:{"click":function($event){return _vm.desactivateFarm(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Inativa")])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }