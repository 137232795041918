<script>
import Confirmation from '@/components/Confirmation.vue'
import {
  typesUseDataType,
  typesUseDataTypeOptions,
  irrigationsDataType,
  irrigationsDataTypeOptions,
  fertilizedsDataType, 
  fertilizedsDataTypeOptions } from '@/utils/enums'
import gql from 'graphql-tag';
import Swal from 'sweetalert2';
import { mapGetters } from 'vuex'


export default {
  components: {
    Confirmation
  },

  apollo: {
    farms: {
      query: gql`
        query Farms {
          farms(
            where: {
              desactivated: {_is_null: true }
            }) 
          {
            id
            name
            city
            state
            country
            customer_id
            desactivated
            customer {
              name
            }
          }
        }
      `,

      fetchPolicy: 'cache-and-network',
    },

    farmsDesactivated: {
      query(){ return gql`
        query farmsDesactivated {
          farms : farms(
            where: {
              desactivated: {_eq: true }
            }) 
          {
            id
            name
            city
            state
            country
            customer_id
            desactivated
            customer {
              name
            }
          }
        }
      `
      },
      
    update: data => data.farms
    },

    
  },

  data() {
    return {
      search: '',
      headers: [
        { text: 'Cliente', value: 'customer.name' },
        { text: 'Nome', value: 'name' },
        { text: 'País', value: 'country' },
        { text: 'UF', value: 'state' },
        { text: 'Município', value: 'city' },
        { text: 'Ações', value: '_actions', sortable: false, align: 'center' },
      ],

      results: [],

      typesUseTypes: typesUseDataType,
      typesUseOptions: typesUseDataTypeOptions,
      irrigationsTypes: irrigationsDataType,
      irrigationsOptions: irrigationsDataTypeOptions,
      fertilizedsTypes: fertilizedsDataType,
      fertilizedsOptions: fertilizedsDataTypeOptions,
    };
  },

  computed: {
    ...mapGetters('auth', ['authStatus', 'user', 'isManager'])
  },

  methods:{
    async remove(id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: uuid! $date: timestamptz) {
              update_farms_by_pk(
                pk_columns: { id: $id },
                _set: {
                  deleted_at: $date
                }
              ) {
                id
              }
            }
          `,
          variables: {
            id,
            date: new Date()
          },
        });
        Swal.fire({
          icon: 'success',
          text: 'Removido com sucesso!',
          position: 'top-end',
          toast: true,
          showConfirmButton: false,
          timer: 3000,
        });
        this.$apollo.queries.farms.refresh()
      } catch (e) {
        console.error(e);
      }
    },

    async exportFarm(farmId) {
      const result = await this.$apollo.query({
          query: gql`
            query MyQuery($farmId: uuid = "") {
              farms(where: {id: {_eq: $farmId}}) {
                name
                modules(order_by: {name: asc}) {
                  name
                  code
                  desired_use
                  irrigation
                  fertilized
                  type_use
                  description
                  start_date
                  paddocks(order_by: {name: asc}) {
                    name
                    code
                    area
                    irrigation  
                    fertilized
                    type_use
                    description
                  }
                }
              }
            }
          `,
          variables: {
            farmId
          },
          fetchPolicy: 'no-cache',
        });
        this.results = [[
          'Fazenda', 

          'Módulo',
          '(M) Código', 
          '(M) Aproveitamento Desejado %',
          '(M) Irrigação',
          '(M) Adubação',
          '(M) Tipo de Uso',
          '(M) Descrição',

          'Piquete', 
          '(P) Código', 
          '(P) Área', 
          '(P) Irrigação', 
          '(P) Adubação', 
          '(P) Tipo de Uso', 
          '(P) Descrição'
        ]]
        const farms = result.data.farms
        for (let farm of farms) {
          for (let module of farm.modules) {
            for (let paddock of module.paddocks) {
              this.results.push([
                farm.name,

                module.name, 
                module.code,
                module.desired_use,
                this.irrigationsTypes[module.irrigation],
                this.fertilizedsTypes[module.fertilized],
                this.typesUseTypes[module.type_use],
                `"${module.description || ''}"`,

                paddock.name, 
                paddock.code,
                paddock.area,
                this.irrigationsTypes[paddock.irrigation],
                this.fertilizedsTypes[paddock.fertilized],
                this.typesUseTypes[paddock.type_use],
                `"${paddock.description || ''}"`
              ])
            }
          }
        }
        this.exportToCsv()
    },

    async desactivateFarm(farm) {
      let farmUpdate = {}
      if (farm.desactivated) {
        farmUpdate.desactivated = null
        farm.desactivated = null
      } else {
        farmUpdate.desactivated = true
        farm.desactivated = true
      }
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($id: uuid!, $farm: farms_set_input!) {
            update_farms_by_pk(pk_columns: { id: $id }, _set: $farm) {
              id
            }
          }
        `,
        variables: {
          id: farm.id,
          farm: farmUpdate,
        },
      });
      this.refetchFarms()
    },

    async refetchFarms() {
      try {
        const result = await this.$apollo.query({
          query: gql`
          query Farms {
          farms(
            where: {
              desactivated: {_is_null: true }
            }) 
          {
            id
            name
            city
            state
            country
            customer_id
            desactivated
            customer {
              name
              }
            }
          }
        `,
          fetchPolicy: 'no-cache',
        });
        console.log('result : ', result)
        if (result && result.data && result.data.farms) {
          this.farms = result.data.farms
        }
      const farmsDesactivated = await this.$apollo.query({
          query: gql`
          query Farms {
          farms(
            where: {
              desactivated: {_eq: true }
            }) 
          {
            id
            name
            city
            state
            country
            customer_id
            desactivated
            customer {
              name
              }
            }
          }
        `,
          fetchPolicy: 'no-cache',
        });
      if (farmsDesactivated && farmsDesactivated.data && farmsDesactivated.data.farms) {
          this.farmsDesactivated = farmsDesactivated.data.farms
        }
      } catch (e) {
        console.error(e)
      }
    },

    exportToCsv() {
      var CsvString = "";
      this.results.forEach(function(RowItem) {
        RowItem.forEach(function(ColItem) {
          if(!ColItem) ColItem = ''
          CsvString += "\uFEFF" + ColItem + ';'
        });
        CsvString += "\r\n";
      });
      CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    var x = document.createElement("A");
    x.setAttribute("href", CsvString );
    x.setAttribute("download","farm-report.csv");
    document.body.appendChild(x);
    x.click();
}
  }
};
</script>

<template>
  <div>
    <h1 class="mb-5">Fazendas</h1>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="farms"
        :search="search"
        :loading="$apollo.loading"
        :items-per-page="10"
        sort-by="customerName"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
            />
            <v-spacer />
            <v-btn v-if="isManager" color="primary" dark to="/fazendas/new"> Novo </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item._actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="user.role === 'manager'" text icon color="primary" :to="`/fazendas/${item.id}`" v-on="on">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="user.role !== 'manager'" text icon color="primary" :to="`/fazendas/${item.id}`" v-on="on">
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <confirmation v-if="user.role === 'manager'" @success="() => remove(item.id)" >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="error" v-on="on">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </confirmation>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green darken-3" @click="exportFarm(item.id)" v-on="on">
                <v-icon small>mdi-microsoft-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar para Excel</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-show="user.role === 'manager' && !item.desactivated" text icon color="green darken-3" @click="desactivateFarm(item)" v-on="on">
                <v-icon small>mdi-circle</v-icon>
              </v-btn>
            </template>
            <span>Ativa</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-show="user.role === 'manager' && item.desactivated" text icon color="error" @click="desactivateFarm(item)" v-on="on">
                <v-icon small>mdi-circle</v-icon>
              </v-btn>
            </template>
            <span>Inativa</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
      <h1 class="mb-5 mt-5">Fazendas Inativas</h1>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="farmsDesactivated"
        :search="search"
        :loading="$apollo.loading"
        :items-per-page="10"
        sort-by="customerName"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Procurar"
              single-line
              hide-details
            />
            <v-spacer />
          </v-toolbar>
        </template>
        <template v-slot:[`item._actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-if="user.role === 'manager'" text icon color="primary" :to="`/fazendas/${item.id}`" v-on="on">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="user.role !== 'manager'" text icon color="primary" :to="`/fazendas/${item.id}`" v-on="on">
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <confirmation v-if="user.role === 'manager'" @success="() => remove(item.id)" >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn text icon color="error" v-on="on">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </confirmation>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn text icon color="green darken-3" @click="exportFarm(item.id)" v-on="on">
                <v-icon small>mdi-microsoft-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar para Excel</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-show="user.role === 'manager' && !item.desactivated" text icon color="green darken-3" @click="desactivateFarm(item)" v-on="on">
                <v-icon small>mdi-circle</v-icon>
              </v-btn>
            </template>
            <span>Ativa</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-show="user.role === 'manager' && item.desactivated" text icon color="error" @click="desactivateFarm(item)" v-on="on">
                <v-icon small>mdi-circle</v-icon>
              </v-btn>
            </template>
            <span>Inativa</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
